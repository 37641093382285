import React from "react";
import _ from "lodash";

import {
    Row,
    Col,
} from "reactstrap";

const FormErrorWidget = ({errors})=>{
    
    const isArrayFlag = Array.isArray(errors);
    return(
        <Row>
            <Col xs="12">
                <div className="alert alert-danger" role="alert">
                    <Row>
                        <Col xs="1" sm="1" className="h3 text-danger">
                                <i className="fa fa-exclamation-triangle " aria-hidden="true"></i>    
                        </Col>
                        <Col>
                        <ul>
                            {   isArrayFlag &&
                                errors.map((e,i)=>{
                                    return(
                                        <li key={i}>
                                            {e}
                                        </li> 
                                    );
                                })

                            }
                            {   !isArrayFlag &&
                                _.values(errors).map((e,i)=>{
                                    return(
                                        <li key={i}>
                                            {e}
                                        </li> 
                                    );
                                })

                            }
                            
                        </ul> 
                        </Col>
                        
                        
                    </Row>
                    
                </div>
            </Col>
        </Row>
    );
}

export default FormErrorWidget;