import React,{Component} from "react";
//import Joi from "joi";
import _ from "lodash";

import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';

import BaseForm from "../BaseClass/Form"
import CreditCardHelper from "../../helpers/CreditCardHelper";
import FormErrorWidget from "../Common/FormErrorWidget";
import ProcessingModal from "../Common/ProcessingModal";

import {scrollTop}     from '../../utils/helperUtils';

import UserService from "../../services/userService";


import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

class PaymentInformation extends BaseForm{

    divAreaToScrollForError = "content-area"

    validationSchema = ""
    /*
    validationSchema = Joi.object({
        creditCardName: Joi.string().required().label('Credit Card Holder Name'), 
    });
    */

    

    errorMessages = {}

    constructor(){
        super();
        this.state = {
            creditCardProcessedSuccessFlag:false,
            processingPaymentModalFlag:false,
            data:{
                creditCardName:"",
            },
            errors:{},
        }
    }

    submitLocal = async ()=>{
        try{
            const paymentFormData = {...this.state.data};
            const {user} = this.props;
            const {user_id:userId,university_id:universityId} = user;

            let errors = {};
            let stripeResult = "";
            
            //Get stripe token
                if (this.props.stripe) {
                    stripeResult = await this.props.stripe.createToken(
                        {
                            type: 'card', 
                            name: paymentFormData.creditCardName,
                        }
                    );
                    //console.log(stripeResult);
                    if(stripeResult.error){
                        errors = (!_.isEmpty(errors)) ? errors : {};
                        errors["invalidCard"] = stripeResult.error.message;
                    }
                    else
                        paymentFormData.stripeToken = stripeResult.token
                } 
                this.setState({errors: errors || {}});
                if(!_.isEmpty(errors)){
                    scrollTop(this.divAreaToScrollForError)
                    return;
                } 
            //End of Get Stripe token

            //Process send payment information to server
                this.setProcessingPaymentModalFlag(true);
                const {status,message,errorObject} = await UserService.saveCustomerCreditCardInformation({
                    ...paymentFormData,
                    userId,
                    universityId,
                });
                
                if(status === true){
                    this.setState({
                        creditCardProcessedSuccessFlag:status
                    })
                }
                this.setProcessingPaymentModalFlag(false);
            //End of process end payment information to server


        }
        catch(ex){
            this.setProcessingPaymentModalFlag(false);
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
        } 
    }

    setProcessingPaymentModalFlag = flag=>{
        this.setState({
            processingPaymentModalFlag:flag,
        })
    }


    validateLocal= ()=>{
        let errors = {};

        const {data} = this.state;
        const {creditCardName} = data;
        
        const amountNumeric = Number(this.state.data.amount);
        if(creditCardName === "")
            errors["creditCardName"] = "Please enter Credit card holders name."
        
            if(_.isEmpty(errors) === true)
          return null;
        else
          return errors;
    }


    render(){
        const {creditCardProcessedSuccessFlag,data,errors,processingPaymentModalFlag} = this.state;
        const {creditCardName} = data;

        const {user} = this.props;
        const {user_id:userId,university_id:universityId} = user;


        const stripeInputFieldStyling = CreditCardHelper.stripeInputFieldStyling;

        return(
            <>
                <div id="content-area" className="mt-2">
                    
                    {/*Error display */}
                    {!_.isEmpty(errors) &&
                        <FormErrorWidget
                        errors={errors}
                        />
                    }
                    {/* End of Error display */}

                    {
                        creditCardProcessedSuccessFlag === true &&  
                        <div>
                            <div className="alert alert-success" role="alert">
                                We have saved the card successfully. <strong>Please click on the "Refresh Window" button to update FileMaker.</strong>
                                <br/><br/>
                                Click <strong><a style={{cursor:"pointer"}} onClick={(e)=>{
                                    e.preventDefault();
                                    window.location = this.props.location.pathname;
                                }}>here</a></strong> to go back to payment form screen.
                            </div>
                        </div>
                    }

                    {
                        userId && 
                        <Row>
                            <Col>
                                <strong>
                                    Add/Update Credit Card for User ID: {userId}
                                </strong>
                            </Col>
                        </Row>
                    }
                    {   
                        creditCardProcessedSuccessFlag === false &&  
                        <div>
                            <FormGroup row>
                                <Label for="creditCardName" sm={4}>Name on Credit Card</Label>
                                <Col sm={6}>
                                    <Input 
                                        type="text" 
                                        name="creditCardName" 
                                        id="creditCardName" 
                                        placeholder="Name on Credit Card" 
                                        value={creditCardName} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                    <Label for="card-number" sm={4}>Credit Card Number</Label>
                                    <Col id="card-number" sm={6}>
                                        <CardNumberElement 
                                            {...stripeInputFieldStyling}
                                        />
                                    </Col>
                            </FormGroup>
                            
                            <FormGroup row>
                                    <Label for="card-expiration-date" sm={4}>Expiration Date</Label>
                                    <Col id="card-expiration-date" sm={6}>
                                        <CardExpiryElement 
                                            {...stripeInputFieldStyling}
                                        />
                                    </Col>
                            </FormGroup> 
                            
                            <FormGroup row>
                                    <Label for="card-cvc" sm={4}>CVC</Label>
                                    <Col id="card-cvc" sm={6}>
                                        <CardCVCElement 
                                            {...stripeInputFieldStyling}
                                        />
                                    </Col>
                            </FormGroup> 

                            <FormGroup row className="justify-content-center">
                                <Col  className="text-center">
                                    <Button 
                                    onClick={this.submitForm}
                                    >
                                    Save
                                    </Button>
                                </Col>
                            </FormGroup>
                        </div>
                    }
                </div>
                <ProcessingModal
                    displayModal={processingPaymentModalFlag}
                    handleCloseOfModalWindow={this.setProcessingPaymentModalFlag}
                />
            </>
        )
    }
}

export default injectStripe(PaymentInformation);

