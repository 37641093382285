import React from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";



import {
    FormGroup,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    Table,
} from "reactstrap";



const DeliveryDateWidget = props=>{
    const {deliveryDate,handleDeliveryDate}= props;
    return(
        <>
            <Table bordered>
                <thead>
                <tr>
                    <th className="text-danger">Delivery Date</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <FormGroup>
                                <InputGroup 
                                    name="deliveryDate"
                                    className="date" 
                                    id="deliveryDate"
                                    
                                >
                                    <ReactDatetime
                                        value={moment(deliveryDate)} 
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onChange={handleDeliveryDate}
                                        inputProps={{
                                            className: "form-control",
                                            placeholder: "Delivery Date",
                                        }}

                                        isValidDate={currentDate=>{
                                            let enableDate = false;
                                            enableDate = (moment.utc(currentDate).isAfter(moment.utc())) ? true:false;
                                            /*
                                            if(enableDate && shippingDateBlockStartDate && shippingDateBlockEndDate){
                                                enableDate = 
                                                    (moment.utc(currentDate).isBefore(moment.utc(shippingDateBlockStartDate), 'day')
                                                        || 
                                                        moment.utc(currentDate).isAfter(moment.utc(shippingDateBlockEndDate), 'day')
                                                    ) ?  true:false;
                                            }*/
                                            return enableDate;
                                        }}
                                    />
                                    <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <span className="glyphicon glyphicon-calendar">
                                        <i className="fa fa-calendar" />
                                        </span>
                                    </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </td>
                    </tr>
                    
                </tbody>
            </Table>
        </>
    )

}

export default DeliveryDateWidget;