import React,{Component} from "react";
import _ from "lodash";
import accounting from "accounting";
import moment from "moment";
import {StripeProvider,Elements} from 'react-stripe-elements';



import BaseForm from "../components/BaseClass/Form"
import CreditCardHelper from "../helpers/CreditCardHelper";
import FormErrorWidget from "../components/Common/FormErrorWidget";
import ProcessingModal from "../components/Common/ProcessingModal";
import {scrollTop}     from '../utils/helperUtils';

import ProductCatalog from "../components/view/OrderSupplies/ProductCatalog"
import ShoppingCartWidget from "../components/view/OrderSupplies/ShoppingCartWidget"
import DeliveryAddressWidget from "../components/view/OrderSupplies/DeliveryAddressWidget"
import DeliveryDateWidget from "../components/view/OrderSupplies/DeliveryDateWidget"

import AddCreditCard from "../components/view/OrderSupplies/AddCreditCard"


import UserService from "../services/userService";


import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container,
    Row,
    Col,
    Table,
    Button
} from 'reactstrap';

/*
   User id is the only incoming request parameter
   componentDidMount(){
    - Query the database to get 
        
        - user
        - univeristy 
        - catalog based on zipcode
   }
   
   UI:
    First row
    - User: <<Name>>     Delivery Address:
    
    Second row
    - Catalog based on zipcode (Left):
        product  price  quantity(Dropdown) Button <Add>
    
    Shopping Cart widget (on the right)
    Delivery date
    <Proceed to Pay button>
    
    Third row
    Card on file
    or 
    Payment information

    Proceed to Pay




*/
class OrderSupplies extends Component {
    divAreaToScrollForError = "content-area"

    constructor(props) {
        super(props);
        this.state = {
            orderProcessedSuccessFlag:false,
            processingPaymentModalFlag:false,
            data:{
                user:"",
                deliveryAddress:"",
                deliveryDate:"",
                university:"",
                productCatalog:"",
                shoppingCart:[],
                cartTotal:{
                    salesTaxPercent:"",
                    salesTax:"",
                    subTotal:"",
                    totalAmount:"",
                },
                useCreditCardOnFile:"",
                paymentInformation:"", //If its a new card then we add the stripe token info here
            },
            errors:{

            }
        }
    }

    async componentDidMount(){
        await this.mapModelToView();
    }

    

    handleAddItemToCart = item=>{
        const {data} = this.state; 
        const {shoppingCart} = data
        const {isBundle,id,quantity} = item;

        if(shoppingCart){
            const index = _.findIndex(shoppingCart,{'isBundle':isBundle,'id':id})
            const cart = [...shoppingCart]
            if(index !== -1){
                if(quantity > 0){
                    cart[index] = item;
                }
                else{
                    cart.splice(index,1)
                }
            }else{
                if(quantity > 0)
                 cart.push(item);
            }

            const cartTotal = this.updateCartTotal(cart);
            this.setState({
                data:{
                    ...this.state.data,
                    shoppingCart:cart,
                    cartTotal,
                }
            })
        }
        
    }

    handleDeliveryDate = value=>{
        console.log(value);
        this.setState({
            data:{
                ...this.state.data,
                deliveryDate:moment(value).format("YYYY-MM-DD"),
            }
        })
    }

    handleAddNewCreditCardError = (errors)=>{
        this.setState({
            errors
        })

        return scrollTop(this.divAreaToScrollForError);
        
    }

    handleNewCreditCardInformtionAndProceedToSubmitOrder = (paymentInformation)=>{
        this.setState({
          data:{
              ...this.state.data,
              paymentInformation,
          }  
        })

        this.purchaseSupplies();
    }
    
    /*
        Get supplies catalog based on users zip code
    */
    mapModelToView = async ()=>{
        try{
            const {userId} = this.props.match.params;
            
            const {status,message,data,errorObject,} = await UserService.getUserAndProductCatalog(userId);
            if(status === true){
                //console.log(data);
                const {user,deliveryAddress,listOfCitiesForSupplies} = data;
                const university = _.pick(user, ['University']).University;
                const cityWithProductCatalog = _.find(listOfCitiesForSupplies,{'universityId':university.university_id})
                
                let [stripeCustomerId,cardExpirationDate,cardLastFourDigits]=["","",""];
                if(user !== ""){
                    ({
                        stripe_customer_id:stripeCustomerId,
                        credit_card_expiration_date:cardExpirationDate,
                        cc_last_four:cardLastFourDigits,
                        
                     } = user);
                }
                
                let useCreditCardOnFile = false;
                const hasCardExpired = moment().isAfter(moment(cardExpirationDate),'day');
                useCreditCardOnFile = hasCardExpired === false ?  true:false;

                //useCreditCardOnFile = false;

                this.setState({
                    data:{
                        ...this.state.data,
                        user:_.omit(user,['University']),
                        deliveryAddress,
                        university,
                        productCatalog:cityWithProductCatalog.products,
                        useCreditCardOnFile,
                        cartTotal:{
                            ...this.state.data.cartTotal,
                            salesTaxPercent:cityWithProductCatalog.salesTax,
                        },
                    }
                })
            }
            else{
                throw Error(message)
            }
        }
        catch(ex){
            //console.log(ex.message);
            this.setState({
                errors:{
                    user:ex.message,
                }
            })
        }

    }

    purchaseSupplies = async ()=>{
        this.setState({errors:""})

        const errors = this.validateLocal();
        if(_.isEmpty(errors) !== true){
            scrollTop(this.divAreaToScrollForError); 
            return;
        }
            
        

        try{
            this.setProcessingPaymentModalFlag(true);
            const formData = _.omit(this.state.data,['productCatalog'])
            const {status,message,errorObject} = await UserService.processSuppliesPurchase(formData);
            
            if(status === true){
                this.setProcessingPaymentModalFlag(false);
            
                this.setState({
                    orderProcessedSuccessFlag:true
                })
            }
            else if(status === false){
                this.setProcessingPaymentModalFlag(false);
            
                this.setState({errors: {serverError:message}})
                setTimeout(()=>scrollTop(this.divAreaToScrollForError),500); 
                return;
            }
            
        
        }
        catch(ex){
            this.setProcessingPaymentModalFlag(false);
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const {message} = ex.response.data;
                const errors = {serverError:message}
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
        }
    }

    setProcessingPaymentModalFlag = flag=>{
        this.setState({
            processingPaymentModalFlag:flag,
        })
    }

    updateCartTotal= (shoppingCart)=>{
        const {data} = this.state; 
        const {cartTotal} = data
        const {salesTaxPercent} = cartTotal;

        if(shoppingCart){
            const subTotal = shoppingCart.reduce((accumulator,item)=>{
                const {title,quantity,price,isBundle,id} = item;
                console.log(item);
                const totalPrice = quantity * price;
                return accumulator += totalPrice          
            
            },0)

            let salesTax = (salesTaxPercent > 0) ? subTotal * (salesTaxPercent/100):0;
            salesTax = accounting.toFixed(salesTax,2) * 1;
            const totalAmount  = accounting.toFixed((subTotal + salesTax),2) * 1;

            return {
                salesTaxPercent,
                salesTax,
                subTotal,
                totalAmount,
            }

            
        }
    }

    

    validateLocal = ()=>{
        let errors = {};

        const {data} = this.state;
        const {deliveryDate,cartTotal,university} = data;
        const {totalAmount} = cartTotal

        let [minimumShoppingCartAmount]=[0];
        if(university !== ""){
            ({minimum_supply_cart_total:minimumShoppingCartAmount} = university);

        }

        if(deliveryDate === "")
            errors["deliveryDate"] = "Please select a Delivery Date"
        if(totalAmount < minimumShoppingCartAmount)
            errors["deliveryDate"] = `The order needs to be a minimum of ${accounting.formatMoney(minimumShoppingCartAmount)}`

        this.setState({
            errors
        })
        
        return errors;
    }

    
    
    render() {
        const {data,errors,orderProcessedSuccessFlag,processingPaymentModalFlag} = this.state;
        const {
            deliveryAddress,
            deliveryDate,
            productCatalog,
            shoppingCart,
            cartTotal,
            useCreditCardOnFile,
            user,
            university,
        } = data;

        let [cardLastFourDigits,cardExpirationDate]=["",""];
        if(user !== ""){
            ({
                cc_last_four:cardLastFourDigits,
                credit_card_expiration_date:cardExpirationDate
            } = user);
        }

        let [minimumShoppingCartAmount]=[0];
        
        if(university !== ""){
            ({minimum_supply_cart_total:minimumShoppingCartAmount} = university);

        }

        const stripeMerchantAccountUniverityId = (user && user.university_id) ? user.university_id : 1;


        return (
            <StripeProvider key={stripeMerchantAccountUniverityId} apiKey={process.env[`REACT_APP_STRIPE_PRODUCTION_KEY_UNIVERSITY_ID_${stripeMerchantAccountUniverityId}`]}> 
                <div>
                    <Container>
                        <div id="content-area" className="mt-2">
                            
                            

                        {   user === "" && _.isEmpty(errors) && 
                            <Row>
                                <Col>
                                    Load Catalog....
                                </Col>
                            </Row>
                        }

                        {
                            orderProcessedSuccessFlag === true &&  
                            <div>
                                <div className="alert alert-success" role="alert">
                                    We have successfully processed the supplies order. <strong>Order confirmation email has been sent out to {user.email} </strong>
                                    <br/><br/>
                                    Click <strong><a style={{cursor:"pointer"}} onClick={(e)=>{
                                        e.preventDefault();
                                        window.location = this.props.location.pathname;
                                    }}>here</a></strong> to place another order.
                                </div>
                            </div>
                        }
                        
                        {
                            user === "" && !_.isEmpty(errors) && 
                            <FormErrorWidget
                                errors={errors}
                            />
                        }

                        {
                            orderProcessedSuccessFlag === false && 
                            <>
                                {   
                                    user !== "" && 
                                    productCatalog !== "" &&
                                    <>
                                        <Row className="my-4">
                                            <Col>
                                                <strong>Name:</strong> {`${user.first_name} ${user.last_name}`}
                                            </Col>
                                            <Col className="text-right">
                                                <strong>User ID:</strong> {`${user.user_id}`}
                                            </Col>
                                        </Row>
                                        
                                        {/*Error display */}
                                        {!_.isEmpty(errors) &&
                                            <FormErrorWidget
                                            errors={errors}
                                            />
                                        }
                                        {/* End of Error display */}
                                    
                                        <Row>
                                            <Col xs="8">
                                                <ProductCatalog
                                                    productCatalog={productCatalog}
                                                    addItemToCart = {this.handleAddItemToCart}
                                                />
                                            </Col>
                                            <Col xs="4">
                                                <ShoppingCartWidget
                                                    shoppingCart={shoppingCart}
                                                    cartTotal = {cartTotal}
                                                    minimumShoppingCartAmount={minimumShoppingCartAmount}
                                                    user={user}
                                                />
                                                <DeliveryAddressWidget
                                                deliveryAddress={deliveryAddress}
                                                />
                                                <DeliveryDateWidget
                                                    deliveryDate={deliveryDate}
                                                    handleDeliveryDate={this.handleDeliveryDate}
                                                />
                                            </Col>
                                        </Row>
                                        {
                                            shoppingCart.length > 0 && useCreditCardOnFile === true && 
                                            <>
                                                <Row>
                                                    <Col xs="8">

                                                        {
                                                            
                                                            <>
                                                            <Table bordered>
                                                                <thead>
                                                                <tr>
                                                                    <th>Payment information on file:</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            {
                                                                            <>
                                                                            Last 4 digits of the Credit Card: {cardLastFourDigits} <br/>
                                                                            Expiration Date: {moment(cardExpirationDate).format("MM/YY")}
                                                        
                                                                            </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                    
                                                                </tbody>
                                                            </Table>
                                                            </>
                                                        }
                                                        
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="8" className="text-center mb-4">
                                                        <Button color="danger"
                                                            onClick={this.purchaseSupplies}
                                                        >Purchase Supplies</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        
                                        {

                                        
                                            shoppingCart.length > 0 && useCreditCardOnFile === false && 
                                            <Row>
                                                <Col xs="8" className="text-center">
                                                    <Elements>
                                                        <AddCreditCard
                                                            {...this.props} 
                                                            user = {user}
                                                            handleOnPurchaseOfSupplies = {this.purchaseSupplies}
                                                            handleAddNewCreditCardError = {this.handleAddNewCreditCardError}
                                                            submitForm = {this.handleNewCreditCardInformtionAndProceedToSubmitOrder} 
                                                        />
                                                    </Elements>
                                                </Col>
                                            </Row>
                                            
                                        
                                        }
                                    </>
                                }
                            </>
                        }
                        </div>
                        <ProcessingModal
                            displayModal={processingPaymentModalFlag}
                            handleCloseOfModalWindow={this.setProcessingPaymentModalFlag}
                        />
                    </Container> 
                </div>
                
            </StripeProvider>
        );
    }
}

export default OrderSupplies;