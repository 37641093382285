import http from "./httpService";

const apiEndpoint = "/legacy/auth";

export async function login(){
    const {data:returnData} = await http.post(apiEndpoint);
    return returnData;
}

export default {
    login
}
