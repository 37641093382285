import React,{Component} from "react";
//import Joi from "joi";
import _ from "lodash";

import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';

import BaseForm from "../../BaseClass/Form"
import CreditCardHelper from "../../../helpers/CreditCardHelper";
import FormErrorWidget from "../../Common/FormErrorWidget";
import ProcessingModal from "../../Common/ProcessingModal";

import {scrollTop}     from '../../../utils/helperUtils';

import UserService from "../../../services/userService";


import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table
} from "reactstrap";

class AddCreditCard extends BaseForm{

    divAreaToScrollForError = "content-area"

    validationSchema = ""
    /*
    validationSchema = Joi.object({
        creditCardName: Joi.string().required().label('Credit Card Holder Name'), 
    });
    */

    

    errorMessages = {}

    constructor(){
        super();
        this.state = {
            creditCardProcessedSuccessFlag:false,
            processingPaymentModalFlag:false,
            data:{
                creditCardName:"",
            },
            errors:{},
        }
    }

    submitLocal = async ()=>{
        const {user,handleAddNewCreditCardError,submitForm} = this.props;
            
        try{
            const paymentFormData = {...this.state.data};
            const {user_id:userId,university_id:universityId} = user;

            let errors = {};
            let stripeResult = "";
            
            //Get stripe token
                if (this.props.stripe) {
                    stripeResult = await this.props.stripe.createToken(
                        {
                            type: 'card', 
                            name: paymentFormData.creditCardName,
                        }
                    );
                    //console.log(stripeResult);
                    if(stripeResult.error){
                        errors = (!_.isEmpty(errors)) ? errors : {};
                        errors["invalidCard"] = stripeResult.error.message;
                    }
                    else
                        paymentFormData.creditCardStripeToken = stripeResult.token
                } 
                if(!_.isEmpty(errors)){
                    handleAddNewCreditCardError(errors)
                    return;
                } 
            //End of Get Stripe token

            //Process send payment information to server
                return submitForm(paymentFormData);
            //End of process end payment information to server


        }
        catch(ex){
            handleAddNewCreditCardError({ccErrors:ex.message})
            return;
           
        } 
    }

    
    validateLocal= ()=>{

    }


    render(){
        const {data,errors} = this.state;
        const {creditCardName} = data;

        const stripeInputFieldStyling = CreditCardHelper.stripeInputFieldStyling;

        return(
            <>
                
                <Table bordered>
                    <thead>
                        <tr>
                            <th className="text-left">Enter new card to be saved to the account:</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <FormGroup row>
                                    <Label className="text-left" for="creditCardName" sm={4}>Name on Credit Card</Label>
                                    <Col sm={6}>
                                        <Input 
                                            type="text" 
                                            name="creditCardName" 
                                            id="creditCardName" 
                                            placeholder="Name on Credit Card" 
                                            value={creditCardName} 
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup row>
                                        <Label className="text-left"  for="card-number" sm={4}>Credit Card Number</Label>
                                        <Col id="card-number" sm={6}>
                                            <CardNumberElement 
                                                {...stripeInputFieldStyling}
                                            />
                                        </Col>
                                </FormGroup>
                                
                                <FormGroup row>
                                        <Label className="text-left"  for="card-expiration-date" sm={4}>Expiration Date</Label>
                                        <Col id="card-expiration-date" sm={6}>
                                            <CardExpiryElement 
                                                {...stripeInputFieldStyling}
                                            />
                                        </Col>
                                </FormGroup> 
                                
                                <FormGroup row>
                                        <Label className="text-left"  for="card-cvc" sm={4}>CVC</Label>
                                        <Col id="card-cvc" sm={6}>
                                            <CardCVCElement 
                                                {...stripeInputFieldStyling}
                                            />
                                        </Col>
                                </FormGroup> 

                                <FormGroup row className="justify-content-center">
                                    <Col  className="text-center">
                                        <Button color="danger" onClick={this.submitForm}>Purchase Supplies</Button>
                                    </Col>
                                </FormGroup>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                    
                
            </>
        )
    }
}

export default injectStripe(AddCreditCard);

