import React,{useState,useEffect} from 'react';
//import Joi from "joi"
import _ from "lodash"

function useFormData(initialData,validationObject) {
    const [data,setData] = useState(initialData)
    const [errors,setErrors] = useState("")
    const [serverErrors,setServerErrors] = useState("")
    const [validationSchema,setValidationSchema] = useState("")
    
    const handleFileChange = (e,name) => {
        //console.log(e.target.files[0])
		setData(
            {
                ...data,
                [name]:e.target.files[0]
            }
        )
	}
    
    /*
      Checkbox should have an array setup
    */
    const handleCheckbox = (name,value)=>{
        const inputValue = value

        const currentDataValue = data[name]
        let insertOperation = false

        let updatedCheckboxData = []
        
        if(typeof currentDataValue === "object"){
            updatedCheckboxData = [...currentDataValue]
            //It's already an array and now check if it exists
            insertOperation = (currentDataValue.includes(inputValue)) ? false:true
            if(insertOperation === true){
                const updatedArray = [...updatedCheckboxData,inputValue]
                setData(
                    {
                        ...data,
                        [name]:updatedArray
                    }
                )
            
            }
            else{
                const checkboxData = updatedCheckboxData.filter(i=>i!==inputValue)
                setData(
                    {
                        ...data,
                        [name]:(checkboxData.length > 0) ? checkboxData:""
                    }
                )
            }
                
            
        }else{
            setData(
                {
                    ...data,
                    [name]:[inputValue]
                }
            )
        }

                
        
    }

    const handleInputChange = ({currentTarget:input})=>{
        setData(
            {
                ...data,
                [input.name]:input.value
            }
        )
    }

    const handleInputSelectBox = (name,option)=>{
        setData(
            {
                ...data,
                [name]:option
            }
        )
    }

    /*
        We have dynamic input arrays so we update data in an array
        nestedAttribute: "a.b" //We only doing 2 levels
        we use lodash _.get(object, 'a.b[0]');

    */
    const handleInputChangeForInputArray = ({currentTarget:input},name,index,nestedAttribute)=>{
        if(nestedAttribute){
            const newData = {...data};
            const nestedAttributeArray =  nestedAttribute.split(".")
            
            const nestedAttributeNode = {...data[nestedAttributeArray[0]]}

            //Get array
            let inputArray = nestedAttributeNode[nestedAttributeArray[1]]

            let newInputItemArray  = inputArray.map((item,idx)=>{
                if(idx === index){
                    return {
                        ...item,
                        [name]:input.value
                    }
                }
                else
                  return item
            })

            nestedAttributeNode[nestedAttributeArray[1]] = newInputItemArray

            newData[nestedAttributeArray[0]] = nestedAttributeNode
            
            setData(
                newData
            )
        }
    }

    const handleInputSelectBoxChangeForInputArray = (option,name,index,nestedAttribute)=>{
        if(nestedAttribute){
            const newData = {...data};
            const nestedAttributeArray =  nestedAttribute.split(".")
            
            const nestedAttributeNode = {...data[nestedAttributeArray[0]]}

            //Get array
            let inputArray = nestedAttributeNode[nestedAttributeArray[1]]

            let newInputItemArray  = inputArray.map((item,idx)=>{
                if(idx === index){
                    return {
                        ...item,
                        [name]:option
                    }
                }
                else
                  return item
            })

            nestedAttributeNode[nestedAttributeArray[1]] = newInputItemArray

            newData[nestedAttributeArray[0]] = nestedAttributeNode
            
            setData(
                newData
            )
        }
    }

    const handleInputChangeNested = ({currentTarget:input},nestedAttribute)=>{
        const newData = {...data};
        
        const nestedAttributeNode = {...data[nestedAttribute]}
        nestedAttributeNode[input.name] = input.value;
        newData[nestedAttribute] = nestedAttributeNode
        
        setData(
            newData
        )
    }

    const handleRadio = (name,value,nestedAttribute=null)=>{
        if(nestedAttribute){
            const newData = {...data};
        
            const nestedAttributeNode = {...data[nestedAttribute]}
            nestedAttributeNode[name] = value;
            newData[nestedAttribute] = nestedAttributeNode
            
            setData(
                newData
            )
        }
        else{
            setData(
                {
                    ...data,
                    [name]:value
                }
            )
        }
    }

    /* Individual field setter: if we want to just set some basic data without input change */
    const setField = ({name,value})=>{
        setData(
            {
                ...data,
                [name]:value
            }
        )
    }

    const setFieldNested = ({name,value,nestedAttribute})=>{
        const newData = {...data};
        const nestedAttributeNode = {...data[nestedAttribute]}
        nestedAttributeNode[name] = value;
        newData[nestedAttribute] = nestedAttributeNode

        setData(
            newData
        )
    }

    const setFieldNestedByIndexPosition = (value,name,index,nestedAttribute)=>{
        if(nestedAttribute){
            const newData = {...data};
            const nestedAttributeArray =  nestedAttribute.split(".")
            
            const nestedAttributeNode = {...data[nestedAttributeArray[0]]}

            //Get array
            let inputArray = nestedAttributeNode[nestedAttributeArray[1]]

            let newInputItemArray  = inputArray.map((item,idx)=>{
                if(idx === index){
                    return {
                        ...item,
                        [name]:value
                    }
                }
                else
                  return item
            })

            nestedAttributeNode[nestedAttributeArray[1]] = newInputItemArray

            newData[nestedAttributeArray[0]] = nestedAttributeNode
            
            setData(
                newData
            )
        }
    }

    /*
        Used to set multiple properties at the same
    */
    const setProperties = (dataObject)=>{
        setData(
            {
                ...data,
                ...dataObject,
            }
        )
    }

    const setMultiplePropertiesForNestedAttributes = ({dataObject,nestedAttribute})=>{
        const newData = {...data};
        const nestedAttributeNode = {...data[nestedAttribute],...dataObject}
        newData[nestedAttribute] = nestedAttributeNode
        
        setData(
            newData
        )
        
        
    }

    /*
    Input:
    {
	  inputfieldName1:{"type":"string","label":"",message:""}
      inputfieldName2:{"type":"string","label":""}
    }

    Output:
    {
        username: Joi.string()
            .alphanum()
            .min(3)
            .max(30)
            .required(),

        password: Joi.string()
            .pattern(new RegExp('^[a-zA-Z0-9]{3,30}$')),

        repeat_password: Joi.ref('password'),

        access_token: [
            Joi.string(),
            Joi.number()
        ],

        birth_year: Joi.number()
            .integer()
            .min(1900)
            .max(2013),

        email: Joi.string()
            .email({ minDomainSegments: 2, tlds: { allow: ['com', 'net'] } })
    }
    */
    const setValidationProperties = (validationObject)=>{
        /*
        if(validationObject){
            const schema = {}
            
            _.forIn(validationObject, function(value, fieldName) {
                const {type,label,message,validationDefinition} = value
                if(type === "string"){
                    schema[fieldName] = Joi.string().required().label(label)
                }
                if(type === "number"){
                    schema[fieldName] = Joi.number().required().label(label)
                }
                if(type === "date"){
                    schema[fieldName] = Joi.date().required().label(label)
                }
                if(type === "email"){
                    schema[fieldName] = Joi.string().email({tlds:false}).required().label(label)
                }
                if(type === "array"){
                    schema[fieldName] = Joi.array().min(1).required().label(label)
                }
                if(type === "boolean"){
                    schema[fieldName] = Joi.boolean().required().label(label)
                }
                if(type === "object"){
                    schema[fieldName] = Joi.object(validationDefinition).required().label(label)
                }
                if(type === "complex"){
                    schema[fieldName] = validationDefinition
                }
            });

            setValidationSchema(Joi.object(schema))
        }
        */
    }

    /*
        Validate data using JOI
        - set the errorObject

        
    */
    const validateData = ()=>{
        /*
        const options = {abortEarly:false,allowUnknown:true};
        const { error, value } = validationSchema.validate(data,options);
        */
        /* error.details
            [
                0:
                context: {label: "First Name", value: "", key: "firstName"}
                message: "\"First Name\" is not allowed to be empty"
                path: ["firstName"]
                type: "string.empty"
                __proto__: Object
                1:
                context: {label: "Last Name", value: "", key: "lastName"}
                message: "\"Last Name\" is not allowed to be empty"
                path: ["lastName"]
                type: "string.empty"
                __proto__: Object
                2:
                context: {label: "Email", value: "", key: "email"}
                message: "\"Email\" is not allowed to be empty"
                path: ["email"]
                type: "string.empty"
                __proto__: Object
                3:
                context: {label: "Phone", value: "", key: "phone"}
                message: "\"Phone\" is not allowed to be empty"
                path: ["phone"]
                type: "string.empty"
        ]
        */
        /*
        if(error){
            const errorObject  = {}
            //console.log(error.details)
            error.details.forEach(e=>{
                const {message,context} = e
                //console.log(e)
                errorObject[context.key] = message
            })
            return errorObject
        }
        else
          return ""
        */

        return ""
    }

    useEffect(()=>{
       return validationObject && setValidationProperties(validationObject)
    },[])

    return {
        data,
        errors,
        handleCheckbox,
        handleFileChange,
        handleInputChange,
        handleInputChangeForInputArray,
        handleInputChangeNested,
        handleInputSelectBox,
        handleInputSelectBoxChangeForInputArray,
        handleRadio,
        serverErrors,
        setData,
        setErrors,
        setField,
        setFieldNested,
        setFieldNestedByIndexPosition,
        setMultiplePropertiesForNestedAttributes,
        setProperties,
        setServerErrors,
        setValidationProperties,
        validateData,
        validationSchema,
    }
}

export default useFormData;