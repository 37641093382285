
const DORMITORY = 1;
const NON_DORMITORY = 2;

const CUSTOMER_TYPE_STUDENT = 1;
const CUSTOMER_TYPE_RESIDENTIAL= 2;
const CUSTOMER_TYPE_COMMERCIAL = 3;
const CUSTOMER_TYPE_STUDENT_TEXT = "student";
const CUSTOMER_TYPE_RESIDENTIAL_TEXT= "residential";
const CUSTOMER_TYPE_COMMERCIAL_TEXT = "commercial";


export default {
    DORMITORY,
    NON_DORMITORY,
    CUSTOMER_TYPE_STUDENT,
    CUSTOMER_TYPE_RESIDENTIAL,
    CUSTOMER_TYPE_COMMERCIAL,
    CUSTOMER_TYPE_STUDENT_TEXT,
    CUSTOMER_TYPE_RESIDENTIAL_TEXT,
    CUSTOMER_TYPE_COMMERCIAL_TEXT,

}