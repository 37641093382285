import React from 'react';

import AddressEntry from '../components/view/CustomerAddressEntry/AddressEntry';

function CustomerAddressPage(props) {
    return (
        <>
          <AddressEntry {...props} />
        </>
    );
}

export default CustomerAddressPage;