import React,{Component} from "react";

import CustomerAccountBalance from "../components/view/CustomerAccountBalance"

import UserService from "../services/userService";


import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container,
    Row,
    Col,
    Jumbotron,
    Button
} from 'reactstrap';


class ProcessAccountBalance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:{
                accountBalance:"",
            },
            errors:{

            }
        }
    }

    componentDidMount(){
        ///console.log("AddCreditCard");
        //console.log(this.props);

        this.mapModelToView();
    }
    
    mapModelToView = async ()=>{
        try{
            const {userId} = this.props.match.params;
            //console.log(userId);

            const {accountBalanceObject} = await UserService.getAccountBalance(userId);

            this.setState({
                data:{
                    ...this.state.data,
                    accountBalance:accountBalanceObject,
                }
            })
        }
        catch(ex){
            console.log(ex);
        }

    }
    
    render() {
        const {data,errors} = this.state;
        const {accountBalance} = data;

        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            <CustomerAccountBalance {...this.props} accountBalanceObject ={accountBalance}/>
                        </Col>
                    </Row>
                </Container> 
            </div>
        );
    }
}

export default ProcessAccountBalance;