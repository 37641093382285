import React,{Component} from "react";
import {StripeProvider,Elements} from 'react-stripe-elements';

import PaymentInformation from "../components/view/CustomerPaymentInformation"

import UserService from "../services/userService";

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container,
    Row,
    Col,
    Jumbotron,
    Button
} from 'reactstrap';

class AddCreditCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:{
                user:"",
            },
            errors:{

            }
        }
    }

    componentDidMount(){
        ///console.log("AddCreditCard");
        //console.log(this.props);

        this.mapModelToView();
    }
    
    mapModelToView = async ()=>{
        try{
            const {userId} = this.props.match.params;
            console.log(userId);

            const userData = await UserService.getUser(userId);

            console.log(userData);
            this.setState({
                data:{
                    ...this.state.data,
                    user:userData,
                }
            })
        }
        catch(ex){
            console.log(ex);
        }

    }

    render() {
        const {data,errors} = this.state;
        const {user} = data;

        const stripeMerchantAccountUniverityId = (user && user.university_id) ? user.university_id : 1;

        return (
            <StripeProvider key={stripeMerchantAccountUniverityId} apiKey={process.env[`REACT_APP_STRIPE_PRODUCTION_KEY_UNIVERSITY_ID_${stripeMerchantAccountUniverityId}`]}> 
                <div>
                    <Container>
                        {
                            user && 
                            <Elements>
                                <PaymentInformation {...this.props} user = {user}/>
                            </Elements>
                        }
                        
                        
                        {   user === "" && 
                            <Row>
                                <Col>
                                    Retrieving User information ....
                                </Col>
                            </Row>
                        }
                    </Container>
                    
                </div>
            </StripeProvider>
        );
    }
}

export default AddCreditCard;