/*
Over here we basically query the server to see if the user is allowed based on IP access
*/
import React,{Component} from "react";

import {login} from "../../services/authService"

class ProtectedRoute extends Component {
    
    constructor(){
        super()
        this.state = {
            allowToProceed:false,
            message:"Authenticating .... Please wait",
        }
    }

    async componentDidMount(){
        try{
            const data  = await login();
            const {status:allowToProceed} = data;
            this.setState({
                allowToProceed
            })
        }
        catch(ex){
            //console.log(ex);
            this.setState({
                message:"Forbidden to access the site",
            })
        }
    }

    render(){
        const {allowToProceed,message} = this.state;
        //console.log(this.props);
        return(
            <>
            
            {
                allowToProceed === true && this.props.children
            }
            
            {
                allowToProceed === false && 
                <p>
                    {message}
                </p>
            }
            </>
        ) 
    }

}

export default ProtectedRoute;