import React,{useEffect,useState} from 'react';
import Select from "react-select";
import _ from "lodash"


import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
} from "reactstrap";

import applicationUtils from '../../../utils/applicationUtils';

import useFormData from '../../../hooks/useFormData';
import userService from '../../../services/userService';
import schoolService from '../../../services/schoolService';

import FormErrorWidget from '../../Common/FormErrorWidget';
import ProcessingModal from '../../Common/ProcessingModal';
import applicationConstant from '../../../utils/applicationConstant';


const initialState = {
    userId:"",
    user:"",
    dormitoryList:[{ value: "", label: "", isDisabled: true }],
    dormitoryObjectList:"",
    pickUpAddress:{
        isDormitory:"No",
        dormitoryName:"",  //{value:"", label:""} this corresponds to the react-select value setup
        dormitoryRoomNumber:"",
        streetNumber:"", //Non dormitory street number
        addressLine1:"",
        addressLine2:"",
        apartmentNumber:"", //Non dormitory apartment number
        zoneId:"",
        city:"",
        state:"",
        postalCode:"",
        country:"USA", 

    }
}

function AddressEntry(props) {
    const {userId,staffUser} = props.match.params;
    
    /* State data */
        const addressEntryFormData = useFormData({  ...initialState})
        const [userRole,setUserRole] = useState("")
        
        const [processingFlag,setProcessingFlag] = useState(false)
        const [serverErrors,setServerErrors] = useState([])
        const [stateList,setStateList] = useState(applicationUtils.getUSStateList())
        const [addressUpdateStatus,setAddressUpdateStatus] = useState(false)

        const user = addressEntryFormData.data.user
      
    /* End of State data */

    /* Effects*/
        useEffect(()=>{
            if(userId)
                getUserById(userId)
        },[userId]) 
        
        useEffect(()=>{
            if(user != "")
                getDormitoryList(user)
        },[user]) 

    /* End of effects */

    /* Handers */
        const getDormitoryList = async user=>{
            try{
                if(typeof user === "object"){
                    const {campus_id:school_id} = user;
                    const school = {id:school_id};

                    const dormitoryList = await schoolService.getDropdownListOfDormitoryBySchool(school);
                    const dormitoryObjectList = await schoolService.getListOfDormitoryBySchool(school);
                    
                    addressEntryFormData.setProperties({
                        dormitoryList,
                        dormitoryObjectList
                    }) 
                }
            }
            catch(ex){
                console.log(ex)
            }
        }

        const getUserById = async userId=>{
            try{
                const userData = await userService.getUser(userId)
                addressEntryFormData.setProperties({
                    userId:userData.user_id,
                    user:userData
                })

                const {customer_type:customerType} = userData
                if(customerType === applicationConstant.CUSTOMER_TYPE_STUDENT){
                    setUserRole(applicationConstant.CUSTOMER_TYPE_STUDENT_TEXT)
                }
                else if(customerType === applicationConstant.CUSTOMER_TYPE_RESIDENTIAL){
                    setUserRole(applicationConstant.CUSTOMER_TYPE_RESIDENTIAL_TEXT)
                }
            }
            catch(ex){
                console.log(ex)
            }
        }

        const getAddressForDisplay = ()=>{
            let displayAddress  = "";
            if(addressEntryFormData.data.user){
                const {line1:addressLine1,line2:addressLine2} = userService.getFormattedUserAddress(addressEntryFormData.data.user);
                return <>{addressLine1} <br/> {addressLine2}</>;
            }
    
            return displayAddress
        }

        const handleSubmit = async ()=>{
            try{
                setProcessingFlag(true)
                setAddressUpdateStatus(false)
                setServerErrors([]) 
                
                let {pickUpAddress:pickUpAddressFormData,user} = addressEntryFormData.data;

                /* Validation */
                if(addressEntryFormData.data){
                    //throw new Error(JSON.stringify(["Enter a message to send an SMS"]))
                    if(pickUpAddressFormData.isDormitory === "Yes"){
                        if( !pickUpAddressFormData.dormitoryName || 
                            !pickUpAddressFormData.city || 
                            !pickUpAddressFormData.state ||
                            !pickUpAddressFormData.postalCode
                        ){
                            throw new Error(JSON.stringify(["Enter Dormitory, city, state and postal code"]))
                        }
                    }
                    else if(pickUpAddressFormData.isDormitory === "No"){
                        if( !pickUpAddressFormData.addressLine1 || 
                            !pickUpAddressFormData.streetNumber || 
                            !pickUpAddressFormData.city || 
                            !pickUpAddressFormData.state ||
                            !pickUpAddressFormData.postalCode
                        ){
                            throw new Error(JSON.stringify(["Enter street #, address line 1, city, state and postal code"]))
                        }
                    }
                }
                /* End of Validation */
                
                //Submit form to the server
                    const pickUpAddress = {
                        isDormitory: (pickUpAddressFormData.isDormitory === "Yes")? true:false,
                        dormitory:{
                            id:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.value:"",
                            name:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryName.label:"",
                            roomNumber:(pickUpAddressFormData.isDormitory === "Yes") ? pickUpAddressFormData.dormitoryRoomNumber:""
                        },
                        streetNumber:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.streetNumber:"",
                        addressLine1:(pickUpAddressFormData.isDormitory !== "Yes") ? pickUpAddressFormData.addressLine1:"",
                        addressLine2:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.addressLine2:"",
                        apartmentNumber:(pickUpAddressFormData.isDormitory !== "Yes") ?pickUpAddressFormData.apartmentNumber:"",
                        city:pickUpAddressFormData.city,
                        //state:(_.has(pickUpAddressFormData,['state','value'])) ? pickUpAddressFormData.state.value:"",
                        state:pickUpAddressFormData.state,
                        postalCode:pickUpAddressFormData.postalCode,
                        country:"USA", 
                    };
        
                    const formData = {
                        updatePrimaryAddressFlag:true,
                        user,
                        staffUser,
                        pickUpAddress,
                    }
                    
                    const {status,data,message} = await userService.updateCustomerAddress(formData);

                    if(!status) throw new Error(JSON.stringify(message))

                    setProcessingFlag(false)
                    setAddressUpdateStatus(true)
                    
            }
            catch(ex){
                console.log(ex)
                setProcessingFlag(false)
                setServerErrors(JSON.parse(ex.message))    
                
            }

        }

        const setDormitory = value => {
            
            const valueObject = value

            const dataObject = {}
            dataObject['dormitoryName'] = valueObject

            const selectedDormitoryObject = _.find(dormitoryObjectList,{"id":valueObject.value});
            const selectedStateObject = _.find(stateList,{"value":selectedDormitoryObject.state});
            dataObject['city'] = selectedDormitoryObject.city
            dataObject['state'] = selectedStateObject
            dataObject['postalCode'] = selectedDormitoryObject.zip
            addressEntryFormData.setMultiplePropertiesForNestedAttributes(
                {
                    dataObject,
                    nestedAttribute:'pickUpAddress',
                }
            )
            
            /*    
            addressEntryFormData.setFieldNested({
                name:"dormitoryName",
                value:value,
                nestedAttribute:"pickUpAddress",
            })
            */
            
        }

        const setDormitoryBasicSelect = value=>{
            console.log(value)
            //console.log(dormitoryObjectList)
            const selectedDormitoryObject = _.find(dormitoryObjectList,{"id":parseInt(value)});
            //console.log(selectedDormitoryObject)
            
            const dataObject = {}
            dataObject['dormitoryName'] = {value:selectedDormitoryObject.id, label:selectedDormitoryObject.name}

            //const selectedStateObject = _.find(stateList,{"value":selectedDormitoryObject.state});
            dataObject['city'] = selectedDormitoryObject.city
            dataObject['state'] = selectedDormitoryObject.state
            dataObject['postalCode'] = selectedDormitoryObject.zip
            addressEntryFormData.setMultiplePropertiesForNestedAttributes(
                {
                    dataObject,
                    nestedAttribute:'pickUpAddress',
                }
            )
            
        }

       
    /* End of Handers */

    /* Single values*/
        const currentAddress = getAddressForDisplay()
        const displaySelectBox = false
        const dormitoryList = addressEntryFormData.data.dormitoryList
        const dormitoryObjectList = addressEntryFormData.data.dormitoryObjectList
        const isDormitory  = (addressEntryFormData.data.pickUpAddress.isDormitory === "Yes") ? true:false
        const pickUpAddress = addressEntryFormData.data.pickUpAddress
    /* End of Single values*/
    
    return (
        <div>
            <Container>
                <div id="content-area" className="mt-2">
                    {/*Error display */}
                    {   serverErrors && serverErrors.length > 0 && 
                            <FormErrorWidget
                                errors={serverErrors}
                            />
                        }
                    {/* End of Error display */}

                    {
                        addressUpdateStatus === true &&  
                        <div>
                            <div className="alert alert-success" role="alert">
                                <strong>Address has been updated </strong>
                                <br/><br/>
                                Click <strong><a style={{cursor:"pointer"}} onClick={(e)=>{
                                    e.preventDefault();
                                    window.location = props.location.pathname;
                                }}>here</a></strong> to update address again.
                            </div>
                        </div>
                    }

                    {   addressUpdateStatus === false &&
                        <>
                            <Row className="my-4">
                                <Col>
                                    <strong>Name:</strong> {`${addressEntryFormData.data.user.first_name} ${addressEntryFormData.data.user.last_name}`}
                                </Col>
                                <Col className="text-right">
                                    <strong>User ID:</strong> {`${addressEntryFormData.data.userId}`}
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col sm={3} md={2}>
                                    <strong>Current Address</strong>
                                </Col>
                                <Col xs="8">
                                    {currentAddress}
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col>
                                    <h5>Enter new campus address:</h5>
                                </Col>
                            </Row>

                            <Form>
                                {
                                    userRole === applicationConstant.CUSTOMER_TYPE_STUDENT_TEXT && 
                                    <FormGroup row>
                                        <Label for="" xs={6} sm={4} md={2} className="font-weight-bold">Is it a dormitory?</Label>
                                        <Col xs={2} sm={1} className="mt-2">
                                            <div>
                                                <Label check>
                                                    <Input
                                                        defaultValue="Yes"
                                                        id="deliveryAddressIsDormitory1"
                                                        name="isDormitory"
                                                        type="radio"
                                                        checked={isDormitory}
                                                        onChange={e=>{addressEntryFormData.setFieldNested({
                                                            name:"isDormitory",
                                                            value:"Yes",
                                                            nestedAttribute:"pickUpAddress",
                                                        })}}
                                                    />
                                                    Yes
                                                    <span className="form-check-sign" />
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col xs={2} sm={1} className="ml-2 mt-2">
                                            <div>
                                                <Label check>
                                                    <Input
                                                        defaultValue="No"
                                                        id="deliveryAddressIsDormitory2"
                                                        name="isDormitory"
                                                        type="radio"
                                                        checked={!isDormitory}
                                                        onChange={e=>{addressEntryFormData.setFieldNested({
                                                            name:"isDormitory",
                                                            value:"No",
                                                            nestedAttribute:"pickUpAddress",
                                                        })}}
                                                    />
                                                    No
                                                    <span className="form-check-sign" />
                                                </Label>
                                            </div>
                                        </Col>
                                    </FormGroup>
                                }

                                {
                                    !isDormitory &&
                                    <>
                                        <FormGroup row>
                                            <Label for="streetNumber" sm={2}>Street #</Label>
                                            <Col sm={4}>
                                                <Input 
                                                type="text" 
                                                name="streetNumber" 
                                                id="streetNumber" 
                                                placeholder="Street #" 
                                                maxLength="6"
                                                value={pickUpAddress.streetNumber} 
                                                onChange={e=>{
                                                    const {currentTarget:input} = e
                                                    addressEntryFormData.setFieldNested({
                                                        name:"streetNumber",
                                                        value:input.value,
                                                        nestedAttribute:"pickUpAddress",
                                                    })}
                                                }
                                                />
                                            </Col>

                                            <Label for="apartmentNumber" sm={2}>Apt #</Label>
                                            <Col sm={4}>
                                                <Input 
                                                type="text" 
                                                name="apartmentNumber" 
                                                id="apartmentNumber" 
                                                placeholder="Apt Number" 
                                                maxLength="10"
                                                value={pickUpAddress.apartmentNumber} 
                                                onChange={e=>{
                                                    const {currentTarget:input} = e
                                                    addressEntryFormData.setFieldNested({
                                                        name:"apartmentNumber",
                                                        value:input.value,
                                                        nestedAttribute:"pickUpAddress",
                                                    })}
                                                }
                                                />
                                            </Col>
                                            
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label for="addressLine1" sm={2}>Street Name</Label>
                                            <Col sm={10}>
                                                <Input 
                                                    type="text" 
                                                    name="addressLine1" 
                                                    id="addressLine1" 
                                                    placeholder="Street Name" 
                                                    value={pickUpAddress.addressLine1} 
                                                    onChange={e=>{
                                                        const {currentTarget:input} = e
                                                        addressEntryFormData.setFieldNested({
                                                            name:"addressLine1",
                                                            value:input.value,
                                                            nestedAttribute:"pickUpAddress",
                                                        })}
                                                    }
                                                />
                                            </Col>
                                            
                                        </FormGroup>
                                        
                                        <FormGroup row className="d-none">
                                            <Label for="addressLine2" sm={2}>Address Line 2</Label>
                                            <Col sm={10}>
                                                <Input 
                                                    type="text" 
                                                    name="addressLine2" 
                                                    id="addressLine2" 
                                                    placeholder="Address Line 2" 
                                                    value={pickUpAddress.addressLine2} 
                                                    onChange={e=>{
                                                        const {currentTarget:input} = e
                                                        addressEntryFormData.setFieldNested({
                                                            name:"addressLine2",
                                                            value:input.value,
                                                            nestedAttribute:"pickUpAddress",
                                                        })}
                                                    }
                                                />
                                            </Col>
                                            
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label for="city" sm={2}></Label>
                                            <Col xs={12} sm={3}>
                                                <Input 
                                                    type="text" 
                                                    name="city" 
                                                    id="city" 
                                                    placeholder="City" 
                                                    value={pickUpAddress.city} 
                                                    onChange={e=>{
                                                        const {currentTarget:input} = e
                                                        addressEntryFormData.setFieldNested({
                                                            name:"city",
                                                            value:input.value,
                                                            nestedAttribute:"pickUpAddress",
                                                        })}
                                                    }
                                                />
                                            </Col>
                                            <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                            <Col xs={12} sm={4}>
                                                <FormGroup>
                                                    <Input
                                                        id="state"
                                                        name="state"
                                                        type="select"
                                                        value={pickUpAddress.state}
                                                        onChange={e=>{
                                                            const {currentTarget:input} = e
                                                            //console.log(input.value)  
                                                            addressEntryFormData.setFieldNested({
                                                                name:"state",
                                                                value:input.value,
                                                                nestedAttribute:"pickUpAddress",
                                                            })     
                                                        }}
                                                    >   
                                                        {stateList.map(s=>{
                                                            return  <option  key={s.value} value={s.value}>
                                                                {s.label}
                                                            </option>
                                                        })}
                                                    </Input>
                                                    {   
                                                        displaySelectBox && 
                                                        <Select
                                                            className="react-select react-select-default d-none"
                                                            classNamePrefix="react-select"
                                                            name="stateOld"
                                                            id="stateOld"
                                                            value={pickUpAddress.state}
                                                            onChange={value => {
                                                                console.log(value)
                                                                addressEntryFormData.setFieldNested({
                                                                    name:"state",
                                                                    value:value,
                                                                    nestedAttribute:"pickUpAddress",
                                                                })
                                                            }}
                                                            options={stateList}
                                                            placeholder="State"
                                                        />
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <Input 
                                                    type="text" 
                                                    name="postalCode" 
                                                    id="postalCode" 
                                                    placeholder="Zip" 
                                                    value={pickUpAddress.postalCode} 
                                                    onChange={e=>{
                                                        const {currentTarget:input} = e
                                                        addressEntryFormData.setFieldNested({
                                                            name:"postalCode",
                                                            value:input.value,
                                                            nestedAttribute:"pickUpAddress",
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </>

                                }

                                {
                                    isDormitory &&
                                    <>
                                        <FormGroup row>
                                            <Label for="pickUpDormitory" sm={2}>Dorm Name</Label>
                                            <Col xs={10} sm={4}>
                                                <FormGroup>
                                                    <Input
                                                        id="dormitoryName"
                                                        name="dormitoryName"
                                                        type="select"
                                                        value={pickUpAddress.dormitoryName.value}
                                                        onChange={e=>{
                                                            const {currentTarget:input} = e
                                                            //console.log(input.value)  
                                                            
                                                            setDormitoryBasicSelect(input.value)
                                                        }}
                                                    >   
                                                        {dormitoryList.map(s=>{
                                                            return  <option key={s.value} value={s.value}>
                                                                {s.label}
                                                            </option>
                                                        })}
                                                    </Input>
                                                    {
                                                        displaySelectBox && 
                                                        <Select
                                                            className="react-select react-select-default d-none"
                                                            classNamePrefix="react-select"
                                                            name="dormitoryNameOld"
                                                            id="dormitoryNameOld"
                                                            value={pickUpAddress.dormitoryName}
                                                            onChange={value=>{ setDormitory(value)}}
                                                            options={dormitoryList}
                                                            placeholder="Dorm"
                                                        />
                                                    }
                                                </FormGroup>
                                            </Col>

                                            <Label for="addressLine1" sm={2}>Room No.</Label>
                                            <Col xs={10} sm={4}>
                                                <Input 
                                                    type="text" 
                                                    name="dormitoryRoomNumber" 
                                                    id="dormitoryRoomNumber" 
                                                    placeholder="Room No" 
                                                    maxLength="10"
                                                    value={pickUpAddress.dormitoryRoomNumber} 
                                                    onChange={e=>{
                                                        const {currentTarget:input} = e
                                                        addressEntryFormData.setFieldNested({
                                                            name:"dormitoryRoomNumber",
                                                            value:input.value,
                                                            nestedAttribute:"pickUpAddress",
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label for="city" sm={2}></Label>
                                            <Col xs={12} sm={3}>
                                                <Input 
                                                    type="text" 
                                                    name="city" 
                                                    id="city" 
                                                    placeholder="City" 
                                                    value={pickUpAddress.city} 
                                                    onChange={e=>{
                                                        const {currentTarget:input} = e
                                                        addressEntryFormData.setFieldNested({
                                                            name:"city",
                                                            value:input.value,
                                                            nestedAttribute:"pickUpAddress",
                                                        })}
                                                    }
                                                />
                                            </Col>
                                            <Label for="state" xs={12} sm={2} className="d-block d-sm-none"></Label>
                                            <Col xs={12} sm={4}>
                                                <FormGroup>
                                                    <Input
                                                        id="state"
                                                        name="state"
                                                        type="select"
                                                        value={pickUpAddress.state}
                                                        onChange={e=>{
                                                            const {currentTarget:input} = e
                                                            //console.log(input.value)  
                                                            addressEntryFormData.setFieldNested({
                                                                name:"state",
                                                                value:input.value,
                                                                nestedAttribute:"pickUpAddress",
                                                            })     
                                                        }}
                                                    >   
                                                        {stateList.map(s=>{
                                                            return  <option key={s.value} value={s.value}>
                                                                {s.label}
                                                            </option>
                                                        })}
                                                    </Input>
                                                    {
                                                        displaySelectBox && 
                                                        <Select
                                                            className="react-select react-select-default d-none"
                                                            classNamePrefix="react-select"
                                                            name="stateOld"
                                                            id="stateOld"
                                                            value={pickUpAddress.state}
                                                            onChange={value => {
                                                                addressEntryFormData.setFieldNested({
                                                                    name:"state",
                                                                    value:value,
                                                                    nestedAttribute:"pickUpAddress",
                                                                })
                                                            }}
                                                            options={stateList}
                                                            placeholder="State"
                                                        />
                                                    }
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12} sm={3}>
                                                <Input 
                                                    type="text" 
                                                    name="postalCode" 
                                                    id="postalCode" 
                                                    placeholder="Zip" 
                                                    value={pickUpAddress.postalCode} 
                                                    onChange={e=>{
                                                        const {currentTarget:input} = e
                                                        addressEntryFormData.setFieldNested({
                                                            name:"postalCode",
                                                            value:input.value,
                                                            nestedAttribute:"pickUpAddress",
                                                        })
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </>

                                }

                                <div className="d-flex justify-content-end">
                                            
                                    <div>
                                        <Button color="primary" className="m-2"
                                            onClick={e=>{
                                                e.preventDefault()
                                                handleSubmit()
                                            }}
                                            type="Submit"
                                        >
                                            Update Address
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </>
                    }
                </div>
                {   processingFlag && 
                    <ProcessingModal
                        displayModal={processingFlag}
                        handleCloseOfModalWindow={setProcessingFlag}
                    />
                }
            </Container>
        </div>
    );
}

export default AddressEntry;