import React,{Component} from "react";
//import Joi from "joi";
import _ from "lodash";
import accounting from "accounting";

import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';

import BaseForm from "../BaseClass/Form"
import CreditCardHelper from "../../helpers/CreditCardHelper";
import FormErrorWidget from "../Common/FormErrorWidget";
import ProcessingModal from "../Common/ProcessingModal";

import {scrollTop}     from '../../utils/helperUtils';

import UserService from "../../services/userService";


import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

class CustomerAccountBalance extends BaseForm{

    divAreaToScrollForError = "content-area"

    validationSchema = ""
    /*
    validationSchema = Joi.object({
        amount: Joi.number().required().label('Amount'), 
    });
    */

    errorMessages = {}

    constructor(){
        super();
        this.state = {
            accountBalanceProcessedSuccessFlag:false,
            processingPaymentModalFlag:false,
            data:{
                amount:"",
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView()
    }   

    componentDidUpdate(prevProps){
        const {accountBalanceObject} = this.props;
        const {accountBalanceObject:accountBalanceObjectPrev} = prevProps;
        const {accountBalance} = accountBalanceObject
        const {accountBalance:accountBalancePrev} = accountBalanceObjectPrev
        
        if(accountBalance !== undefined && accountBalance !== accountBalancePrev)
            this.mapModelToView()
    }

    mapModelToView = ()=>{

        const {accountBalanceObject} = this.props;
        const {userId, accountBalance} = accountBalanceObject
        if(accountBalance !== undefined){    
            this.setState({
                data:{
                    ...this.state.data,
                    amount:accountBalance,
                }
            })
        }
    
         
    }

    submitLocal = async ()=>{
        try{
            console.log("hello");
            const {accountBalanceObject} = this.props;
            const {userId, accountBalance} = accountBalanceObject
            
            //Process send payment information to server
            this.setProcessingPaymentModalFlag(true);
            
            const {status,message,errorObject} = await UserService.processAccountBalance({
                amount: Number(this.state.data.amount),
                userId,
                accountBalance,
            });
            
            if(status === true){
                this.setState({
                    accountBalanceProcessedSuccessFlag:status
                })
            }
            this.setProcessingPaymentModalFlag(false);
            //End of process end payment information to server

            
        }
        catch(ex){
            console.log(ex);            
            this.setProcessingPaymentModalFlag(false);
            /*
            400 signals validation errors from the server
            renderError() basically will display server side error other than code 400
            */
            if(ex.response && ex.response.status === 400){ 
                const errors = ex.response.data;
                
                this.setState({errors: errors || {}});
                if(errors){ scrollTop(this.divAreaToScrollForError); return;}
            }
        } 
    }

    setProcessingPaymentModalFlag = flag=>{
        this.setState({
            processingPaymentModalFlag:flag,
        })
    }


    validateLocal= ()=>{
        let errors = {};

        const {accountBalanceObject} = this.props;
        const {userId, accountBalance} = accountBalanceObject
            

        const {data} = this.state;
        const {amount} = data;
        
        const amountNumeric = Number(this.state.data.amount);
        if(isNaN(amountNumeric) === true)
            errors["amount"] = "Please enter a valid amount."
        else if(amountNumeric > accountBalance)
            errors["amount"] = `The amount cannot be greater than the account balance ${accounting.formatMoney(accountBalance)}`
        

        if(_.isEmpty(errors) === true)
          return null;
        else
          return errors;
    }


    render(){
        const {accountBalanceProcessedSuccessFlag,data,errors,processingPaymentModalFlag} = this.state;
        const {amount} = data;
        const {accountBalanceObject} = this.props;

        let userId, accountBalance;
        if(typeof accountBalanceObject === "object"){
            ({userId, accountBalance} = accountBalanceObject)
        }

        
        return(
            <>
                <div id="content-area" className="mt-2">
                    
                    {/*Error display */}
                    {!_.isEmpty(errors) &&
                        <FormErrorWidget
                        errors={errors}
                        />
                    }
                    {/* End of Error display */}

                    {
                        accountBalanceProcessedSuccessFlag === true &&  
                        <div>
                            <div className="alert alert-success" role="alert">
                                We have processed the balance successfully. <strong>Please click on the "Refresh Window" button to update FileMaker.</strong>
                                <br/><br/>
                                Click <strong><a style={{cursor:"pointer"}} onClick={(e)=>{
                                    e.preventDefault();
                                    window.location = this.props.location.pathname;
                                }}>here</a></strong> to go back to account balance screen.
                            </div>
                        </div>
                    }

                    {   
                        accountBalanceProcessedSuccessFlag === false &&  
                        <div>
                            <h3>Charge an account</h3>
                            <div>
                                User Id: {userId || ""}<br/>
                                Balance: {accountBalance ? accounting.formatMoney(accountBalance):""}
                            </div>
                            <FormGroup row>
                                <Label for="amount" sm={4}>Amount to charge:</Label>
                                <Col sm={6}>
                                    <Input 
                                        type="text" 
                                        name="amount" 
                                        id="amount" 
                                        placeholder="Enter amount" 
                                        value={amount} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </FormGroup>

                            

                            <FormGroup row className="justify-content-start">
                                <Col  className="text-left">
                                    <Button 
                                    onClick={this.submitForm}
                                    >
                                    Process Charges
                                    </Button>
                                </Col>
                            </FormGroup>
                        </div>
                    }
                </div>
                <ProcessingModal
                    displayModal={processingPaymentModalFlag}
                    handleCloseOfModalWindow={this.setProcessingPaymentModalFlag}
                />
            </>
        )
    }
}

export default CustomerAccountBalance;

