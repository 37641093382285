import React,{useEffect,useState} from 'react';

import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
} from "reactstrap";

import useFormData from '../../../hooks/useFormData';
import userService from '../../../services/userService';

import FormErrorWidget from '../../Common/FormErrorWidget';
import ProcessingModal from '../../Common/ProcessingModal';

const truckRouteList = ["",1,2]

const initialState = {
    userId:"",
    user:"",
    products:"",
    smsMessage:"",
    truckRoute:"1"
}

function PageDriverView(props) {
    const {userId,messageType,staffUser} = props.match.params;

    const [processingFlag,setProcessingFlag] = useState(false)
    const [smsSentStatus,setSmsSentStatus] = useState(false)
    
    const [serverErrors,setServerErrors] = useState([])

    /* State data */
        const pageDriverFormData = useFormData(
            {
                ...initialState
            }
        )
    /* End of State data */

    /* Effects*/
        useEffect(()=>{
            if(userId)
                getLaundryDetailForUser({userId,messageType,staffUser})
        },[userId])            
    
    /* End of effects */

    /* Handers */
        const getLaundryDetailForUser = async userId=>{
            try{
                const {status,data,message} = await userService.getLaundryDetailForUser(userId)
                if(!status)
                    throw new Error(message)
                
                const {user,products,smsDefaultMessage:smsMessage} = data
                pageDriverFormData.setProperties({
                    userId:user.user_id,
                    user,
                    products,
                    smsMessage,
                })
                
            }
            catch(ex){
                console.log(ex)
            }
        }

        const handleSubmit = async ()=>{
            try{
                setProcessingFlag(true)
                setSmsSentStatus(false)
                setServerErrors([]) 
                
                /* Validation */
                if(!pageDriverFormData.data.smsMessage || !pageDriverFormData.data.truckRoute){
                    throw new Error(JSON.stringify(["Enter a message and truck route to page driver"]))
                }
                /* End of Validation */
                
                //Submit form to the server
                    const {userId,user,products,smsMessage,truckRoute} = pageDriverFormData.data
                        
                    const {status,data,message} = await userService.pageDriverForLaundry(
                        {
                            userId,
                            user,
                            products,
                            smsMessage,
                            truckRoute,
                            staffUser
                        }
                    )    

                    if(!status) throw new Error(JSON.stringify(message))
                    
                    setProcessingFlag(false)
                    setSmsSentStatus(true)
                    pageDriverFormData.setProperties({
                        userId:"",
                        user:"",
                        products:"",
                        smsMessage:"",
                        truckRoute:"1"
                    })
            }
            catch(ex){
                console.log(ex)
                setProcessingFlag(false)
                setServerErrors(JSON.parse(ex.message))    
                
            }
        }
    /* End of Handers */


    return (
        <div>
            <Container>
                <div id="content-area" className="mt-2">
                    {/*Error display */}
                        {   serverErrors && serverErrors.length > 0 && 
                            <FormErrorWidget
                                errors={serverErrors}
                            />
                        }
                    {/* End of Error display */}

                    {
                        smsSentStatus === true &&  
                        <div>
                            <div className="alert alert-success" role="alert">
                                <strong>Sent an SMS to driver successfully!! </strong>
                                <br/><br/>
                                Click <strong><a style={{cursor:"pointer"}} onClick={(e)=>{
                                    e.preventDefault();
                                    window.location = props.location.pathname;
                                }}>here</a></strong> to send another message.
                            </div>
                        </div>
                    }
                    {   smsSentStatus === false && 
                        <>
                            <Row className="my-4">
                                <Col>
                                    <strong>Name:</strong> {`${pageDriverFormData.data.user.first_name} ${pageDriverFormData.data.user.last_name} (${pageDriverFormData.data.userId})`}
                                </Col>
                                <Col className="text-right">
                                    <strong>Phone:</strong> {`${pageDriverFormData.data.user.cell}`}
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col xs="2">
                                    <FormGroup>
                                        <Label htmlFor="truckRoute" className="font-weight-bold">Truck Route:</Label>
                                        
                                        <Input
                                            id="truckRoute"
                                            name="truckRoute"
                                            type="select"
                                            value={pageDriverFormData.data.truckRoute}
                                            onChange={e=>{
                                                //const {currentTarget:input} = e
                                                //console.log(input.value) 
                                                pageDriverFormData.handleInputChange(e,"truckRoute") 
                                                
                                            }}
                                        >   
                                            {truckRouteList.map((s,i)=>{
                                                return  <option key={i} value={s}>
                                                    {s}
                                                </option>
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="smsMessage" className="font-weight-bold">Message:</Label>
                                        <Input
                                            type="textarea"
                                            name="smsMessage"
                                            id="smsMessage"
                                            placeholder="Enter message to send Sms"
                                            value={pageDriverFormData.data.smsMessage}
                                            onChange={(e)=>pageDriverFormData.handleInputChange(e,"smsMessage")}
                                            rows="5"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-end">
                                
                                <div>
                                    <Button color="primary" className="m-2"
                                        onClick={handleSubmit}
                                    >
                                        Send Sms
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                </div>

                {   processingFlag && 
                    <ProcessingModal
                        displayModal={processingFlag}
                        handleCloseOfModalWindow={setProcessingFlag}
                    />
                }
            </Container>
        </div>
    );
}

export default PageDriverView;