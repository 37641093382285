import React,{Component} from "react";
//import Joi from "joi";
import _ from "lodash";
import accounting from "accounting";

import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';

import BaseForm from "../../BaseClass/Form"

import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
} from "reactstrap";

class ShoppingCartWidget extends BaseForm{

    divAreaToScrollForError = "content-area"

    validationSchema = ""
    
    errorMessages = {}

    constructor(){
        super();
        this.state = {
            data:{
                
            },
            errors:{},
        }
    }

    componentDidMount(){
        
    }   

    componentDidUpdate(prevProps){
        
    }

    getMinumumAmountForSupplyOrderDisplay = ()=>{
        const {minimumShoppingCartAmount} = this.props
        let displayText = "";
        
        if(minimumShoppingCartAmount > 0)
             displayText = ` [Min Purchase: ${accounting.formatMoney(minimumShoppingCartAmount)}]`;
 
        return displayText;
    }

    mapModelToView = ()=>{

        
    
         
    }

    submitLocal = async ()=>{
        try{
            
            
        }
        catch(ex){
            
        } 
    }

    validateLocal= ()=>{

    }


    render(){
        
        const {shoppingCart,cartTotal} = this.props
        const {
            salesTaxPercent,
            salesTax,
            subTotal,
            totalAmount,
        } = cartTotal;

        const minimumShoppingCartAmountDisplay = this.getMinumumAmountForSupplyOrderDisplay()
        return(
            <>
                <Table bordered>
                    <thead>
                    <tr>
                        <th colSpan="3">Your Cart <span className="text-danger">{minimumShoppingCartAmountDisplay}</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        shoppingCart.length === 0 &&
                        <tr>
                            <td colSpan="3">
                                No items in cart
                            </td>
                        </tr>
                    }
                    {
                        shoppingCart.length > 0 &&
                        shoppingCart.map(item=>{
                            const {title,quantity,price,isBundle,id} = item;
                            const totalPrice = accounting.formatMoney(quantity * price);
                            return <tr key={isBundle === true ?`bundle_${id}`:`individual_${id}`}>
                                        <td>
                                            {title}
                                        </td>
                                        <td>
                                            {quantity}
                                        </td>
                                        <td className="text-right">
                                            {totalPrice}
                                        </td>
                                    </tr> 
                        })
                    }

                    {
                        shoppingCart.length > 0 &&
                        <>
                            <tr>
                                <td colSpan="2" className="text-right">
                                    Sub Total
                                </td>
                                <td className="text-right">
                                    {accounting.formatMoney(subTotal)}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="text-right">
                                    Sales Tax {`(${salesTaxPercent})`}
                                </td>
                                <td className="text-right">
                                    {accounting.formatMoney(salesTax)}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="text-right">
                                    Total
                                </td>
                                <td className="text-right">
                                    {accounting.formatMoney(totalAmount)}
                                </td>
                            </tr>
                        </>
                    }

                     
                    </tbody>          
                </Table>
            </>
        )
    }
}

export default ShoppingCartWidget;

