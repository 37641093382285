const monthOptions = [
    { value: "", label: " Choose Month", isDisabled: true },
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" }
  ];

const yearOption = [
    { value: "", label: " Choose Year", isDisabled: true },
];

const getMonthList = ()=>{
        return monthOptions;
}

const getYearList = ()=>{

    const currentYear = new Date().getFullYear();
    const maxYear = currentYear + 10;
    
    let yearListEntry = null;
    for(let i= currentYear; i<= maxYear; i++){
        
        yearListEntry = {
            value: ""+i, label: ""+i
        }
        yearOption.push(yearListEntry);
    }

    return yearOption;

}

const stripeInputFieldStyling = {
    style: {
      base: {
        border:'1',
        fontWeight:'100',
        fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',  
        '::placeholder': {
            color: '#999',
            fontWeight:'100',
        }
      }
    },
};




export default {
    getMonthList,
    getYearList,
    stripeInputFieldStyling,
};