import React from 'react';

import SendSmsView from "../components/view/SendSms/SendSmsView"

function SendSmsPage(props) {
    return (
        <>
          <SendSmsView
            {...props}
          />
        </>
    );
}

export default SendSmsPage;