import React from "react";


import {
    Table,
} from "reactstrap";



const DeliveryAddressWidget = props=>{
    const {deliveryAddress}= props;
    const {line1:addressLine1,line2:addressLine2} = deliveryAddress
    return(
        <>
            <Table bordered>
                <thead>
                <tr>
                    <th>Delivery Address</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {
                                <>
                                {addressLine1} <br/> {addressLine2}
                                </>
                            }
                        </td>
                    </tr>
                    
                </tbody>
            </Table>
        </>
    )

}

export default DeliveryAddressWidget;