import React,{Component} from "react";
//import Joi from "joi";
import _ from "lodash";
import accounting from "accounting";

import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';

import BaseForm from "../../BaseClass/Form"

import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
} from "reactstrap";

const PRODUCT_TYPE_BUNDLE = "bundles";
const PRODUCT_TYPE_INDIVIDUAL_ITEM= "individualProduct";


class ProductCatalog extends BaseForm{
    productTypes = [PRODUCT_TYPE_BUNDLE,PRODUCT_TYPE_INDIVIDUAL_ITEM];

    divAreaToScrollForError = "content-area"

    validationSchema = ""
    
    errorMessages = {}

    constructor(){
        super();
        this.state = {
            data:{
                
            },
            errors:{},
        }
    }

    componentDidMount(){
        this.mapModelToView()
    }   

    componentDidUpdate(prevProps){
        
    }

    handleAddItemToCart = (item)=>{
        const {addItemToCart} = this.props 
        addItemToCart(item);
    }

    handleQuantityChange = (item,quantity)=>{
        const {data} = this.state; 

        const {isBundle,id} = item;
        //Update local state 
        const productType = (isBundle === true) ? PRODUCT_TYPE_BUNDLE:PRODUCT_TYPE_INDIVIDUAL_ITEM;
        const index = _.findIndex(data[productType],{'isBundle':isBundle,'id':id})
        if(index !== -1){
            
            const updatedItem = {...data[productType][index]}
            updatedItem.quantity = parseInt(quantity);
            
            const updatedProductTypeList = [...data[productType]]
            updatedProductTypeList[index] = updatedItem;
            
            const dataToUdpate = {
                ...this.state.data,
                [productType]:updatedProductTypeList,
            }
            
            this.setState({
                data:dataToUdpate
            })

            this.handleAddItemToCart(updatedItem);
            
        }

    }

    mapModelToView = ()=>{
        const {productCatalog} = this.props
        const data = {...productCatalog}
        
        //Add quantity property to state
        this.productTypes.forEach(productType=>{
            if(data[productType]){
                data[productType].forEach((item,key)=>{
                    data[productType][key]['quantity'] = 0;
                })
            }
        });

        this.setState({data})
        


    
         
    }

    submitLocal = async ()=>{
        try{
            
            
        }
        catch(ex){
            
        } 
    }

    validateLocal= ()=>{

    }


    render(){
        const {data} = this.state; 
        //console.log(data);
        let incrementCtr  = 1;
        return(
            <>
                <Table bordered>
                    <thead>
                    <tr>
                        <th>Product</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th className="d-none"></th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            this.productTypes.map(productType=>{
                                if(data[productType]){
                                    return data[productType].map((item,key)=>{
                                        //console.log(item);
                                        const {title,price,quantity} = item;
                                        return (<tr key={incrementCtr++}>
                                                    <td>
                                                        {title}
                                                    </td>
                                                    <td>
                                                        {`${accounting.formatMoney(price)}`}
                                                    </td>
                                                    <td>
                                                        <Input type="select" name="select" id="exampleSelect"
                                                            value={quantity}
                                                            onChange={e=>{
                                                                const {currentTarget:input} = e
                                                                this.handleQuantityChange(item,input.value);
                                                            }}
                                                        >
                                                            <option>0</option>
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                            <option>6</option>
                                                            <option>7</option>
                                                            <option>8</option>
                                                            <option>9</option>
                                                            <option>10</option>
                                                        </Input>
                                                    </td>
                                                    <td className="d-none">
                                                        <Button color="primary" size="sm" onClick={e=>{
                                                                const {currentTarget:input} = e
                                                                this.handleAddItemToCart(item);
                                                        }}>
                                                            Update 
                                                        </Button>
                                                    </td>
                                                </tr> );
                                    });
                                    
                                }
                            })
                        }
                    </tbody>            
                </Table>
            </>
        )
    }
}

export default ProductCatalog;

