import React,{Component} from "react";
import {Route,Switch,Redirect} from "react-router-dom";

import ProtectedRoute from "./components/auth/ProtectedRoute";

import AddCreditCard from "./pages/AddCreditCard"
import CustomerAddressPage from "./pages/CustomerAddressPage"
import ProcessAccountBalance from "./pages/ProcessAccountBalance"
import OrderSupplies from "./pages/OrderSupplies"
import SendSms from "./pages/SendSms"
import PageDriver from "./pages/PageDriver"

import LandingPage from "./pages/Default"

class App extends Component {
    constructor(props) {
        super(props);

        
    }
    
    render() {
        return (
            <React.Fragment>
                <Switch>    
                    <Route
                        path="/address-entry/:userId/:staffUser"
                        render={props => <ProtectedRoute {...props} ><CustomerAddressPage {...props}  /></ProtectedRoute>}
                    />
                    
                    <Route
                        path="/add-credit-card/:userId"
                        render={props => <ProtectedRoute {...props} ><AddCreditCard {...props}  /></ProtectedRoute>}
                    />
                    
                    <Route
                        path="/process-account-balance/:userId"
                        render={props => <ProtectedRoute {...props} ><ProcessAccountBalance {...props}  /></ProtectedRoute>}
                    />

                    <Route
                        path="/order-supplies/:userId"
                        render={props => <ProtectedRoute {...props} ><OrderSupplies {...props}  /></ProtectedRoute>}
                    />

                    <Route
                        path="/send-sms/:userId"
                        render={props => <ProtectedRoute {...props} ><SendSms {...props}  /></ProtectedRoute>}
                    />

                    <Route
                        path="/page-driver/:userId/:messageType/:staffUser"
                        render={props => <ProtectedRoute {...props} ><PageDriver {...props}  /></ProtectedRoute>}
                    />

                    <Route
                        path="/index"
                        render={props => <LandingPage {...props}  />}
                    />

                    <Redirect to="/index" />
                </Switch>
            </React.Fragment>
        );
    }
}

export default App;