import React from 'react';

import PageDriverView from "../components/view/PageDriver/PageDriverView"

function PageDriverPage(props) {
    return (
        <>
          <PageDriverView
            {...props}
          />
        </>
    );
}

export default PageDriverPage;