import http from "./httpService";

import applicationConstant from "../utils/applicationConstant";

const apiLegacyEndpoint = "/legacy";

async function getAccountBalance(id){
    const {data:returnData} = await http.get(`${apiLegacyEndpoint}/getAccountBalance/${id}`);
    return returnData;
}

function getFormattedUserAddress(userRecord){
    let [formattedAddress,completeStreetAddress] = ["",""];
    const {
        customer_type:customerType,
        dorm_non_dorm:dormitoryOrNonDormitory,
        apartment:apartmentNumber,
        school_city:city,
        school_state:state,
        school_zip:zip,
        street:addressLine1,
        home,
    } = userRecord;

    if(customerType === applicationConstant.CUSTOMER_TYPE_STUDENT){
        if(dormitoryOrNonDormitory === applicationConstant.DORMITORY){
            formattedAddress = {
                line1:`${addressLine1} ${home}`,
                line2:`${city}, ${state} - ${zip}`,
            }

        }
        else if(dormitoryOrNonDormitory ===  applicationConstant.NON_DORMITORY){
            completeStreetAddress = `${home} ${addressLine1}`;
            if(apartmentNumber)
                completeStreetAddress = `${completeStreetAddress}, Apt:${apartmentNumber}`;
               
            formattedAddress = {
                line1:completeStreetAddress,
                line2:`${city}, ${state} - ${zip}`,
            }
        }
    }
    else if(customerType === applicationConstant.CUSTOMER_TYPE_RESIDENTIAL){
            completeStreetAddress = `${home} ${addressLine1}`;
            if(apartmentNumber)
                completeStreetAddress = `${completeStreetAddress}, Apt:${apartmentNumber}`;
               
            formattedAddress = {
                line1:completeStreetAddress,
                line2:`${city}, ${state} - ${zip}`,
            }
    }

    return formattedAddress;
}

async function getUser(id){
    const {data:returnData} = await http.get(`${apiLegacyEndpoint}/getUserRecord/${id}`);
    return returnData;
}

async function getLaundryDetailForUser({userId,messageType,staffUser:staffUserId}){
    const {data:returnData} = await http.get(`${apiLegacyEndpoint}/getLaundryDetailForUser/${userId}/${messageType}/${staffUserId}`);
    return returnData;
}

async function getUserAndProductCatalog(id){
    const {data:returnData} = await http.get(`${apiLegacyEndpoint}/getUserAndProductCatalog/${id}`);
    return returnData;
}

async function pageDriverForLaundry(formData){
    const {data:returnData} = await http.post(`${apiLegacyEndpoint}/page-driver-for-laundry`,formData);
    return returnData;
}


async function processAccountBalance(formData){
    const {data:returnData} = await http.post(`${apiLegacyEndpoint}/process-account-balance`,formData);
    return returnData;
}

async function processSuppliesPurchase(formData){
    const {data:returnData} = await http.post(`${apiLegacyEndpoint}/process-supplies-purchase`,formData);
    return returnData;
}


async function saveCustomerCreditCardInformation(formData){
    const {data:returnData} = await http.post(`${apiLegacyEndpoint}/save-payment-information`,formData);
    return returnData;
}

async function sendSms(formData){
    const {data:returnData} = await http.post(`${apiLegacyEndpoint}/send-sms`,formData);
    return returnData;
}

const updateCustomerAddress = async (formData)=>{
    const {data:returnData} = await http.post(`${apiLegacyEndpoint}/update-customer-address`,formData);
    return returnData;
}

export default {
    getAccountBalance,
    getFormattedUserAddress,
    getLaundryDetailForUser,
    getUser,
    getUserAndProductCatalog,
    pageDriverForLaundry,
    processAccountBalance,
    processSuppliesPurchase,
    saveCustomerCreditCardInformation,
    sendSms,
    updateCustomerAddress,
}
