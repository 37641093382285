//import Raven from "raven-js";


function init(){
    /*
    Raven.config('https://967b5d3269a34cf1a6bf1f009f2a6034@sentry.io/1493630',
    {
      release:'1-0-0',
      environment:'development-test'
    }
    ).install()
    */
 }
 
 function log(error){
    //Raven.captureException(error);
    console.log(error);
 }
 
 export default {
  init,
  log
 }