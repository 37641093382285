import axios from 'axios';
import logger from './logService';

console.log(`process.env.REACT_APP_API_URL: ${process.env.REACT_APP_API_URL}`);
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
//axios.defaults.baseURL = "http://api.mylazybones.com:3011/api";
//console.log(axios.defaults.baseURL);

axios.interceptors.response.use(null,error=>{
   //debugger;
   const expectedErrors = error.response && error.response.status >= 400 && error.response.status <=500;
   
   if(!expectedErrors){
     //console.log(error);
     logger.log(error);
     //toast.error("Unexpected Error occurred");
     //toast("Unexpected Error occurred");
   }
   
   return Promise.reject(error);

})

export default {
  get:axios.get,
  post:axios.post,
  delete:axios.delete,
  put:axios.put,
}