import React,{useEffect,useState} from 'react';

import {
    Badge,
    Button,
    Container,
    Card,
    CardBody,
    Row,
    Col,
    Form,
    FormGroup, 
    Label, 
    Input, 
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Table,
} from "reactstrap";

import useFormData from '../../../hooks/useFormData';
import userService from '../../../services/userService';

import FormErrorWidget from '../../Common/FormErrorWidget';
import ProcessingModal from '../../Common/ProcessingModal';


const initialState = {
    userId:"",
    user:"",
    smsMessage:"",
}

function SendSmsView(props) {
    const {userId} = props.match.params;

    const [processingFlag,setProcessingFlag] = useState(false)
    const [smsSentStatus,setSmsSentStatus] = useState(false)
    
    const [serverErrors,setServerErrors] = useState([])

    /* State data */
        const smsFormData = useFormData(
            {
                ...initialState
            }
        )
    /* End of State data */

    /* Effects*/
        useEffect(()=>{
            if(userId)
                getUserById(userId)
        },[userId])            
    
    /* End of effects */

    /* Handers */
        const getUserById = async userId=>{
            try{
                const userData = await userService.getUser(userId)
                smsFormData.setProperties({
                    userId:userData.user_id,
                    user:userData
                })
            }
            catch(ex){
                console.log(ex)
            }
        }

        const handleSubmit = async ()=>{
            try{
                setProcessingFlag(true)
                setSmsSentStatus(false)
                setServerErrors([]) 
                
                /* Validation */
                if(!smsFormData.data.smsMessage){
                    throw new Error(JSON.stringify(["Enter a message to send an SMS"]))
                }
                /* End of Validation */
                
                //Submit form to the server
                    const {userId,user,smsMessage} = smsFormData.data
                        
                    const {status,data,message} = await userService.sendSms(
                        {
                            userId,
                            user,
                            phone:user.cell,
                            smsMessage,
                        }
                    )    

                    if(!status) throw new Error(JSON.stringify(message))
                    
                    setProcessingFlag(false)
                    setSmsSentStatus(true)
                    smsFormData.setProperties({
                        smsMessage:""
                    })

                    
                    
            }
            catch(ex){
                console.log(ex)
                setProcessingFlag(false)
                setServerErrors(JSON.parse(ex.message))    
                
            }
        }
    /* End of Handers */


    return (
        <div>
            <Container>
                <div id="content-area" className="mt-2">
                    {/*Error display */}
                        {   serverErrors && serverErrors.length > 0 && 
                            <FormErrorWidget
                                errors={serverErrors}
                            />
                        }
                    {/* End of Error display */}

                    {
                        smsSentStatus === true &&  
                        <div>
                            <div className="alert alert-success" role="alert">
                                <strong>SMS send to cell number {smsFormData.data.user.cell} </strong>
                                <br/><br/>
                                Click <strong><a style={{cursor:"pointer"}} onClick={(e)=>{
                                    e.preventDefault();
                                    window.location = props.location.pathname;
                                }}>here</a></strong> to send another message.
                            </div>
                        </div>
                    }
                    {   smsSentStatus === false && 
                        <>
                            <Row className="my-4">
                                <Col>
                                    <strong>Name:</strong> {`${smsFormData.data.user.first_name} ${smsFormData.data.user.last_name}`}
                                </Col>
                                <Col className="text-right">
                                    <strong>User ID:</strong> {`${smsFormData.data.userId}`}
                                </Col>
                            </Row>
                            <Row className="my-4">
                                <Col>
                                    <strong>Phone:</strong> {`${smsFormData.data.user.cell}`}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="smsMessage" className="font-weight-bold">Message:</Label>
                                        <Input
                                            type="textarea"
                                            name="smsMessage"
                                            id="smsMessage"
                                            placeholder="Enter message to send Sms"
                                            value={smsFormData.data.smsMessage}
                                            onChange={(e)=>smsFormData.handleInputChange(e,"smsMessage")}
                                            rows="5"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-end">
                                
                                <div>
                                    <Button color="primary" className="m-2"
                                        onClick={handleSubmit}
                                    >
                                        Send Sms
                                    </Button>
                                </div>
                            </div>
                        </>
                    }
                </div>

                {   processingFlag && 
                    <ProcessingModal
                        displayModal={processingFlag}
                        handleCloseOfModalWindow={setProcessingFlag}
                    />
                }
            </Container>
        </div>
    );
}

export default SendSmsView;