import _ from "lodash";

const UsaStates = require('usa-states').UsaStates;

const stateOption = [
    { value: "", label: " Choose State", isDisabled: true },
];


const stateList = new UsaStates().states;
stateList.forEach(s => {
    const state = {
        value:s.abbreviation,
        label:s.name
    }
    stateOption.push(state);
});


function getUSStateList(){
    return stateOption;
}

export default {
    getUSStateList,
}


