import React,{Component} from "react";

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

class Main extends Component {
    constructor(props) {
        super(props);

        
    }
    

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            Mylazybones Legacy site
                        </Col>
                    </Row>
                </Container>
                
            </div>
        );
    }
}

export default Main;