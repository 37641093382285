import _ from "lodash";

import http from "./httpService";

const apiCommonSettingEndpoint = "/commonSettings";


async function getDropdownListOfDormitoryBySchool(school){
    try{
          const {data:listOfDormitoryBySchools} = await http.get(`${apiCommonSettingEndpoint}/getListOfDormitoriesBySchool`);
          //console.log(listOfDormitoryBySchools);

          let dormitoryList = "";
          listOfDormitoryBySchools.forEach(s =>{
                if(school.id === s.schoolId) dormitoryList = s.dormitories;
          });
          
          let dormitoryOption = [
                { value: "", label: " Choose Dorm", isDisabled: true },
          ];
    
          if(!_.isEmpty(dormitoryList)){
                dormitoryList.forEach(d => {
                      const dormitory = {
                            value:d.id,
                            label:d.name
                      }
                      dormitoryOption.push(dormitory);
                });
           }
          
          return dormitoryOption;

    }catch(ex){
       console.log(ex);
    }

    
    
}

async function getListOfDormitoryBySchool(school){
    try{
          const {data:listOfDormitoryBySchools} = await http.get(`${apiCommonSettingEndpoint}/getListOfDormitoriesBySchool`);
          //console.log(listOfDormitoryBySchools);

          let dormitoryList = "";
          listOfDormitoryBySchools.forEach(s =>{
                if(school.id === s.schoolId) dormitoryList = s.dormitories;
          });
    
          return dormitoryList;

    }catch(ex){
       console.log(ex);
    }
}

export default {
    getDropdownListOfDormitoryBySchool,
    getListOfDormitoryBySchool,
    
}